import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Img } from 'gatsby-image';

const ProjectThumbnail = styled.article`
	width: 100%;
	cursor: pointer;
	margin: 20px;
	width: calc(100% - 40px);

	@media (min-width: 1024px) {
		width: calc(33.3333% - 40px);
	}
`;

const ProjectAsset = styled.img`
	max-width: 100%;
	width: 100%;
`;

const ProjectLink = styled(Link)`
	cursor: pointer;
	color: inherit;
	text-decoration: none;
`;

const ProjectTitle = styled.p`
	margin-top: 15px;
`;

const ThumbnailComponent = ({ cover, path, title }) => {
	return (
		<ProjectThumbnail>
			<ProjectLink to={`/archive/${path}`}>
				<ProjectAsset src={cover.url} alt={title} />
				<ProjectTitle>{title}</ProjectTitle>
			</ProjectLink>
		</ProjectThumbnail>
	);
};

export default ThumbnailComponent;
