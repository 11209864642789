import React from 'react';
import Layout from '../components/Layout/Layout';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import SEO from '../components/seo';
import Thumbnail from '../components/Archive/Thumbnail';

const Archive = styled.main`
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;

	@media (min-width: 1024px) {
		flex-direction: row;
	}
`;

const Header = styled.header`
	position: sticky;
	top: 0;
	padding: 20px;
	display: flex;
	justify-content: space-between;
`;

const HeaderTitle = styled.h3`
	text-transform: uppercase;
	cursor: pointer;

	a {
		text-decoration: none;
		color: inherit;

		&:hover {
			text-decoration: underline;
		}
	}

	@media (min-width: 1024px) {
		font-size: 18px;
	}
`;

const ArchivePage = () => {
	const data = useStaticQuery(graphql`
		{
			allSanityProject(
				filter: {
					slug: { current: { ne: null } }
					cover: { asset: { url: { ne: null } } }
				}
			) {
				nodes {
					title
					slug {
						_key
						current
					}
					cover {
						asset {
							url
						}
					}
				}
			}
		}
	`);

	const projects = data.allSanityProject.nodes;

	return (
		<Layout>
			<SEO title="Archive" />
			<Header>
				<HeaderTitle>
					<Link to="/">Keenan Wetzel</Link>
				</HeaderTitle>
				<HeaderTitle>Archive</HeaderTitle>
			</Header>
			<Archive>
				{projects.map((proj, index) => (
					<Thumbnail
						key={index}
						cover={proj.cover.asset}
						path={proj.slug.current.toLowerCase()}
						title={proj.title}
					/>
				))}
			</Archive>
		</Layout>
	);
};

export default ArchivePage;
